import * as L from 'lucid-cardano'
import {Validator} from './oada/types'

export const refUtxoMap: { [scriptHash: string]: { outRef: string, utxo?: L.UTxO } } = {
  "903d444cc678f9a5993e6f260325e63d193e9d976a6c389e95c9c08e": {
    outRef: "a895a59bbc893dba1783c79484d686cef6dd54e4e4d56a7b52468bf26cbc04f2#0"
  },
  "380b35d7911da830f69a5ddbb2990781fb97313738b7afed88c59ed5": {
    outRef: "63db97e2394c11742d9f66fd0b34499ecba6df34a1d1b1b4ee67135d4b7dce2b#0"
  },
  "6f19c57f1a8a16c36ddb03e066474dcb272c68aa8a136f25505fbfd1": {
    outRef: "cfb4f16f804e2f4eedac081451754db0ee2e6759182c966aca52fd494effd7e7#0"
  },
  "dd55f231e23e63678e4c6c4367071c7b092e735ab9c3db6d36a0b08f": { 
    outRef: "806bf5f1cda8829e1a91506f2f3f1d24caeb0a4caa1f20ff366dac471b4bfbf4#0" 
  },
  "f6db94fad6b06f804fc36cc665df0291fbfc42b979cd388b99d8fbf5": { 
    outRef: "e1c354e7b9034423c2c3f964a2121142df3fd685d8af9f53da5a1d13fc543464#0" 
  },
  "664569dde707eb3c2ff4c9283f4031f5cd2038668e9f1d50cb728f74": { 
    outRef: "cbf57035055e1c9b8f346162cb811cf0b05561e6f071c8c19c140a375e5dbd0e#0" 
  },
  "1075476c62cdc3b51a340fa392f2ef03a177d5a7a1451a059c957dfb": { 
    outRef: "6018418d749a6464713efed321aa4da1f4cb71227a434c8967e9c5397551788a#0" 
  },
  "909d7f282d8696217b556a8522c46d9fb08aac62a3327013bb40ed6b": { 
    outRef: "c4c60b7769cad2fce1e76c76630dfc1244b77324faa370c27c4993f9c10bd8bd#0" 
  },
  "339a00ccbe238ea1842ba92b9cdcb0016fad3d1896eb1209ce0ed7f5": { 
    outRef: "5eba4a5903bccfea9b65f384bfc4f8881b43550e8196b181aeb7d36a609c5380#0" 
  },
  "3a0f80f1c98fdd50364b69bd588e6b91042909509ca28bce7910547d": { 
    outRef: "0f8b44a3d6bcee58bb0ab9a2370d3a786e04469e5447a78b1157a9a971b78eba#0" 
  },
  "c6d4c7af979c7af77cfac2c1c9f0ce9ccc0ea92a7915c54ce3da6569": { 
    outRef: "ce9099b3ccaa24f83ff130cb77e1e48f7ac23eb8b68948b0fe8f5ea21e5ba1ab#0" 
  },
  "f6099832f9563e4cf59602b3351c3c5a8a7dda2d44575ef69b82cf8d": { 
    outRef: "7e78fb2ebf82e9a16ad27fc9a8cafa24dc3446f944859c9621f8e41afd58a514#0" 
  },
  "ce0a9beb82234239e8e4194527f05aca3dd5ecde1221e7fe06c3f630": { 
    outRef: "9c59bf7dd607df4ba7f27de2ad6ea3bb98837d2d325fe88dab55496a41f7dd5e#0" 
  },
  "5e96822160e48edc1216656f35061379645b328fb77e3292d9e36271": { 
    outRef: "c232d3fe14a7de42a3b4f9034b30d41dc3944621d23a4980fed8eac13db72289#0" 
  },
  "02a574e2f048e288e2a77f48872bf8ffd61d73f9476ac5a83601610b": { 
    outRef: "80d58a6e8d5191f8f01d5bca9de3bce1a43b11d02e61e1c3a6f0996ae9976c5e#0" 
  },
  "ca8b02920131f6c0b30c12bfffed8b0a9d3cc7d046eb0372caa02694": { 
    outRef: "9ad8e50c6c269cb8742705bf54a24b5006d8be31c64035d0213ca1d31cc9452a#0" 
  },
  "5d3df99fcfbbf282bd76a3d76a2e30bdd22e61c56f1462447938933b": { 
    outRef: "43da5e009b4c7d8594c5dc51d3901b89da9d64d208b7b2fa8980a90c42601132#0" 
  },
  "eeed2b7f8deded8011bd05628a9f440cd01bceb70496159cc03a5d4f": { 
    outRef: "dcbf871dff86a785e38fa98d7a25e399a8f19dbd39cd715ff05c6da2ac8840fb#0" 
  },
  "4fa34a7b4d48767f87f41fc6494b16cfdb4aaf497a694ec571c16cb4": { 
    outRef: "f67a4e0593d8124575c30d5a27e78a14017474f3bfb35c85f999042327420a10#0" 
  },
  "b893ffb4ffc7b8df65d95369875b3ab4a911e1689e302968e155229e": { 
    outRef: "a6860571a00c9b58bcbbb28805d863f538a2ba5db576e99ff78c9b8a92f22f65#0" 
  },
}

export const attachScriptOrRef = async (tx: L.Tx, validator: Validator) => {
  if (validator.hash in refUtxoMap) {
    const utxo = refUtxoMap[validator.hash].utxo
    if (utxo)
      tx.readFrom([utxo])
  } else {
    tx.attachSpendingValidator(validator.validator)
  }
}

