import {
  C,
  Data,
  Lucid,
  Tx,
} from 'lucid-cardano'
import * as L from 'lucid-cardano'
import UtilScripts from './oada/scripts/utils.json'
import NewUtilScripts from './oada/scripts/new-utils.json'
import {mkScriptUtils} from './oada/utils'
import {AssetClass, assetClassSchema} from './bond/plutus-v1-encoders'
import {fromPlutusData, toPlutusData} from './bond/schema'
import {Validator} from './oada/types'
import {attachScriptOrRef} from './ref-utxo-map'
import {MintNft, toWrappedData, _x} from './oada/datums'

// hack to force evaluate the datums module until I can figure out the right way
const _xx = _x

export enum WhitelistProtocol {
  OTOKEN = 0,
  OPTIM_TOKEN = 1
}

export enum OtokenAsset {
  OADA = 0,
  OOTHER = 1
}

export enum OtokenWhitelistPurpose {
  OTOKEN_RULE = 0,
  SOTOKEN_RULE = 1,
  CONTROLLER = 2,
  STRATEGY = 3,
  STAKE_AUCTION = 4
}

export enum OptimTokenWhitelistPurpose {
  SUPPLY_RULE = 0,
}

export type WhitelistNamespace = OtokenAsset | 0

export type WhitelistPurpose = OtokenWhitelistPurpose | OptimTokenWhitelistPurpose

export const whitelistId = (
  protocol: WhitelistProtocol,
  namespace: WhitelistNamespace,
  purpose: WhitelistPurpose
) => {
  return protocol * 10000 + namespace * 100 + purpose
}

export const mkWhitelistUtils = (
  lucid: Lucid,
  soulToken: AssetClass,
  protocol: WhitelistProtocol,
  namespace: WhitelistNamespace
) => {
  const scriptUtils = mkScriptUtils(lucid)

  const {
    loadValidator,
  } = scriptUtils;

  const newTx = (...scripts: Validator[]) => {
    const tx = lucid.newTx()
    for (const script of scripts)
      attachScriptOrRef(tx, script)
    return tx
  }

  const whitelistMap: { [purpose in WhitelistPurpose]?: Validator } = {
  }

  const loadOldWhitelist = (purpose: WhitelistPurpose) => {
    if (whitelistMap[purpose] !== undefined)
      throw new Error("Duplicate import of whitelist")

    const whitelist = loadValidator(
      UtilScripts,
      'whitelist.mint',
      [toPlutusData(soulToken), BigInt(whitelistId(protocol, namespace, purpose))]
    )
    whitelistMap[purpose] = whitelist
    return whitelist
  }

  const loadWhitelist = (purpose: WhitelistPurpose) => {
    if (whitelistMap[purpose] !== undefined)
      throw new Error("Duplicate import of whitelist")

    const whitelist = loadValidator(
      NewUtilScripts,
      'whitelist.mint',
      [toPlutusData(soulToken), BigInt(whitelistId(protocol, namespace, purpose))]
    )
    whitelistMap[purpose] = whitelist
    return whitelist
  }

  const seedUtxoToMintRedeemer = (
    seedUtxo: L.UTxO
  ): MintNft => {
    return {
        kind: 'MintNft',
        txOutRef: {
          kind: 'TxOutRef',
          txId: { kind: 'TxId', id: seedUtxo.txHash },
          txIdx: BigInt(seedUtxo.outputIndex)
        }
      }
  }

  const utxoToTokenName = (
    seedUtxo: L.UTxO
  ) => {
    const mintRedeemer = seedUtxoToMintRedeemer(seedUtxo)
    return Buffer.from(C.hash_blake2b256(new Uint8Array(Buffer.from(Data.to(toPlutusData(mintRedeemer.txOutRef)), 'hex')))).toString('hex')
  }

  const mintId = async (
    validator: Validator,
    datum: Data,
    seedUtxo: L.UTxO,
    refScript?: string,
    attachScript: boolean = true
  ): Promise<Tx> => {
    const tokenName = utxoToTokenName(seedUtxo)
    const mintRedeemer = seedUtxoToMintRedeemer(seedUtxo)
    const tx = newTx()
      .collectFrom([seedUtxo])
      .mintAssets(
        { [validator.hash + tokenName]: 1n },
        Data.to(toPlutusData(mintRedeemer))
      )
      .payToAddressWithData(
        validator.mkAddress(),
        {
          inline: Data.to(datum),
          scriptRef: refScript ? {
            script: refScript,
            type: 'PlutusV2'
          } : undefined
        },
        { [validator.hash + tokenName]: 1n }
      )

      if (attachScript)
        attachScriptOrRef(tx, validator)

      return tx
  }

  const burnId = async (
    validator: Validator,
    datum?: Data,
  ): Promise<Tx | undefined> => {
    const mintRedeemer = { kind: 'BurnNft' }
    const whitelistUtxos = await lucid.utxosAt(validator.mkAddress())
    const burnedUtxos =
      whitelistUtxos.filter(utxo => (datum === undefined) || (utxo.datum === Data.to(datum)))
    const burnedTokens = burnedUtxos.flatMap(utxo => {
      const id = Object.entries(utxo.assets).find(([unit, _quantity]) => unit.startsWith(validator.hash))
      if (id !== undefined)
        return [[id[0], -1n]]
      else
        return []
    })
    if (burnedTokens.length === 0)
      return undefined
    return newTx(validator)
      .collectFrom(burnedUtxos, Data.to(toWrappedData(mintRedeemer)))
      .mintAssets(
        Object.fromEntries(burnedTokens),
        Data.to(toPlutusData(mintRedeemer))
      )
  }

  const getWhitelistValidator = (purpose: WhitelistPurpose) => {
    const validator = whitelistMap[purpose]
    if (validator === undefined)
      throw new Error(`Operation on unknown whitelist: ${whitelistId(protocol, namespace, purpose)}`)
    return validator
  }
  
  const whitelistAdd = async (purpose: WhitelistPurpose, datum: any, refScript?: string) => {
    const validator = getWhitelistValidator(purpose)
    const utxos = await lucid.wallet.getUtxos()
    return mintId(validator, toPlutusData(datum), utxos[0], refScript)
  }

  const whitelistRemove = async (purpose: WhitelistPurpose, datum?: any) => {
    const validator = getWhitelistValidator(purpose)
    return burnId(validator, datum && toPlutusData(datum))
  }
  
  const whitelistKeys = async(purpose: WhitelistPurpose): Promise<string[]> => {
    const validator = getWhitelistValidator(purpose)
    const utxos = await lucid.utxosAt(validator.mkAddress())
    return utxos.map(utxo => Data.from(utxo.datum!))
  }

  const whitelistIds = async(purpose: WhitelistPurpose): Promise<AssetClass[]> => {
    const validator = getWhitelistValidator(purpose)
    const utxos = await lucid.utxosAt(validator.mkAddress())
    return utxos.map(utxo => fromPlutusData(assetClassSchema, Data.from(utxo.datum!)))
  }

  const referenceWhitelist = async(whitelist: Validator, hash?: string): Promise<Tx> => {
    const whitelistUtxos = await lucid.utxosAt(whitelist.mkAddress())
    return newTx()
      .readFrom(whitelistUtxos.filter(utxo => !hash || utxo.datum === Data.to(hash)))
  }

  const referenceIdWhitelist = async(whitelist: Validator, id: AssetClass): Promise<Tx> => {
    const whitelistUtxos = await lucid.utxosAt(whitelist.mkAddress())
    return newTx()
      .readFrom(whitelistUtxos.filter(utxo => utxo.datum === Data.to(toPlutusData(id))))
  }


  return {
    loadOldWhitelist,
    loadWhitelist,

    whitelistAdd,
    whitelistRemove,
    whitelistKeys,
    whitelistIds,
    referenceWhitelist,
    referenceIdWhitelist,

    newTx, 
  }
}

