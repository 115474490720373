import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BasicResponse, FailResponse } from '../bond/utils';
import { AppDispatch, RootState, Services } from '../store/index';
import makeJSONBigInt, { } from "json-bigint"
import { oadaEndpointsUrl, oadaFeeAddress, oadaMintFee, oadaStakeFee } from '../config.local';
import { TxRecipe, txRecipeToTx } from '../tx-recipe';
import { getUtxos, GYValueOut, lucidToGYTxOutRef } from '../bond/actions';
import { getLatestUtxosFromPersistedVirtualWalletUtxoMap, makeVirtualWalletUtxoMap, updatePersistedVirtualWalletUtxoMap } from '../bond/wallet-stuff';
import { lucid } from '../store/hooks';
import Big from 'big.js';
import { UITypes } from 'src/types/ui';
import { UTxO } from 'lucid-cardano';
import { bech32AddressToPaymentPkh } from 'src/bond/lucid';
import { oadaNetwork } from 'src/network';
import { addressToStakeAddress, relativeEpochToAbsoluteEpoch } from 'src/bond/getters/slice';
import { bidIdToTxOutRef } from './view';

const JSONBigInt = makeJSONBigInt({ useNativeBigInt: true, alwaysParseAsBig: true, constructorAction: 'preserve' })
const Json = JSONBigInt

type OadaState = {
  buyOadaResponse: BasicResponse<string> | undefined,
  stakeOadaResponse: BasicResponse<string> | undefined,
  unstakeOadaResponse: BasicResponse<string> | undefined,
  stakeAuctionBidResponse: BasicResponse<string> | undefined,
  cancelStakeAuctionBidResponse: BasicResponse<string> | undefined,
  cancelStakeOrderResponse: BasicResponse<string> | undefined,
  getOadaFrontendInfoResponse: BasicResponse<OadaFrontendInfo> | undefined,
  getStakeLockHistoricVolumeResponse: BasicResponse<number> | undefined,
  getSoadaHistoricalReturnResponse: BasicResponse<SoadaHistoricalReturn> | undefined
  getStakeAuctionVolumeResponse: BasicResponse<StakeAuctionVolume> | undefined
  getOadaGeneralInfoResponse: BasicResponse<OadaGeneralInfo> | undefined,
  lockOptimizResponse: BasicResponse<string> | undefined,
  unlockOptimizResponse: BasicResponse<string> | undefined,
  getOptimizToOptimInfoResponse: BasicResponse<OptimizToOptimInfo> | undefined,
}

const initialState: OadaState = {
  buyOadaResponse: undefined,
  stakeOadaResponse: undefined,
  unstakeOadaResponse: undefined,
  stakeAuctionBidResponse: undefined,
  cancelStakeAuctionBidResponse: undefined,
  cancelStakeOrderResponse: undefined,
  getOadaFrontendInfoResponse: undefined,
  getStakeLockHistoricVolumeResponse: undefined,
  getSoadaHistoricalReturnResponse: undefined,
  getStakeAuctionVolumeResponse: undefined,
  getOadaGeneralInfoResponse: undefined,
  lockOptimizResponse: undefined,
  unlockOptimizResponse: undefined,
  getOptimizToOptimInfoResponse: undefined,
}

export const oadaActionsSlice = createSlice({
  name: 'oada-actions',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(buyOada.fulfilled, (state, action) => {
        state.buyOadaResponse = action.payload
      })
      .addCase(buyOada.rejected, (state, err) => {
        state.buyOadaResponse = err.payload
        console.error("Buy oada request failed:", err);
      })
      .addCase(stakeOada.fulfilled, (state, action) => {
        state.stakeOadaResponse = action.payload
      })
      .addCase(stakeOada.rejected, (state, err) => {
        state.stakeOadaResponse = err.payload
        console.error("Stake oada request failed:", err);
      })
      .addCase(unstakeOada.fulfilled, (state, action) => {
        state.unstakeOadaResponse = action.payload
      })
      .addCase(unstakeOada.rejected, (state, err) => {
        state.unstakeOadaResponse = err.payload
        console.error("Unstake oada request failed:", err);
      })
      .addCase(stakeAuctionBid.fulfilled, (state, action) => {
        state.stakeAuctionBidResponse = action.payload
      })
      .addCase(stakeAuctionBid.rejected, (state, err) => {
        state.stakeAuctionBidResponse = err.payload
        console.error("Stake auction bid request failed:", err);
      })
      .addCase(cancelStakeAuctionBid.fulfilled, (state, action) => {
        state.cancelStakeAuctionBidResponse = action.payload
      })
      .addCase(cancelStakeAuctionBid.rejected, (state, err) => {
        state.cancelStakeAuctionBidResponse = err.payload
        console.error("Stake auction bid cancel request failed:", err);
      })
      .addCase(cancelStakeOrder.fulfilled, (state, action) => {
        state.cancelStakeOrderResponse = action.payload
      })
      .addCase(cancelStakeOrder.rejected, (state, err) => {
        state.cancelStakeOrderResponse = err.payload
        console.error("Stake order cancel request failed:", err);
      })
      .addCase(getOadaFrontendInfo.fulfilled, (state, action) => {
        state.getOadaFrontendInfoResponse = action.payload
      })
      .addCase(getStakeLockHistoricVolume.fulfilled, (state, action) => {
        state.getStakeLockHistoricVolumeResponse = action.payload
      })
      .addCase(getSoadaHistoricalReturn.fulfilled, (state, action) => {
        state.getSoadaHistoricalReturnResponse = action.payload
      })
      .addCase(getStakeAuctionVolume.fulfilled, (state, action) => {
        state.getStakeAuctionVolumeResponse = action.payload
      })
      .addCase(getOadaFrontendInfo.rejected, (state, err) => {
        state.getOadaFrontendInfoResponse = err.payload
        console.error("Get oada frontend info request failed:", err);
      })
      // optimiz locks
      .addCase(lockOptimiz.fulfilled, (state, action) => {
        state.lockOptimizResponse = action.payload
      })
      .addCase(lockOptimiz.rejected, (state, err) => {
        state.lockOptimizResponse = err.payload
        console.error("Lock optimiz request failed:", err);
      })
      .addCase(unlockOptimiz.fulfilled, (state, action) => {
        state.unlockOptimizResponse = action.payload
      })
      .addCase(unlockOptimiz.rejected, (state, err) => {
        state.unlockOptimizResponse = err.payload
        console.error("Unlock optimiz request failed:", err);
      })
      .addCase(getOptimizToOptimInfo.fulfilled, (state, action) => {
        state.getOptimizToOptimInfoResponse = action.payload
      })
      .addCase(getOptimizToOptimInfo.rejected, (state, err) => {
        state.getOptimizToOptimInfoResponse = err.payload
        console.error("Get optimiz to optim info request failed:", err);
      })
  }
})

export const oadaReducer = oadaActionsSlice.reducer

export const selectBuyOadaResponse = (state: RootState): BasicResponse<string> | undefined => {
  return state.oadaActions.buyOadaResponse
}

export const selectStakeOadaResponse = (state: RootState): BasicResponse<string> | undefined => {
  return state.oadaActions.stakeOadaResponse
}

export const selectUnstakeOadaResponse = (state: RootState): BasicResponse<string> | undefined => {
  return state.oadaActions.unstakeOadaResponse
}

export const selectStakeAuctionBidResponse = (state: RootState): BasicResponse<string> | undefined => {
  return state.oadaActions.stakeAuctionBidResponse
}

export const selectCancelStakeAuctionBidResponse = (state: RootState): BasicResponse<string> | undefined => {
  return state.oadaActions.cancelStakeAuctionBidResponse
}

export const selectCancelStakeOrderResponse = (state: RootState): BasicResponse<string> | undefined => {
  return state.oadaActions.cancelStakeOrderResponse
}

export const selectOadaFrontendInfo = (state: RootState): OadaFrontendInfo | undefined => {
  const response = state.oadaActions.getOadaFrontendInfoResponse
  if (response === undefined) {
    return undefined
  } else if (response.tag === 'Fail') {
    console.error(`Failed to get oada frontend info: ${response.contents}`)
    return undefined
  } else {
    return response.contents
  }
}

export const selectOadaLpToken = (state: RootState): Token | undefined => {
  const response = state.oadaActions.getOadaFrontendInfoResponse
  if (response === undefined) {
    return undefined
  } else if (response.tag === 'Fail' || response.contents === undefined) {
    console.error(`Failed to get oada frontend info: ${response.contents}`)
    return undefined
  } else {
    const [policyId, tokenName] = response.contents.lpAssetClass.split('.')
    return {
      policyId,
      tokenName
    }
  }
}

export const selectStakeLockHistoricVolume = (state: RootState): number | undefined => {
  const response = state.oadaActions.getStakeLockHistoricVolumeResponse
  if (response === undefined) {
    return undefined
  } else if (response.tag === 'Fail') {
    console.error(`Failed to get stake lock historic volume: ${response.contents}`)
    return undefined
  } else {
    return response.contents
  }
}

export const selectSoadaHistoricalReturn = (state: RootState): SoadaHistoricalReturn | undefined => {
  const response = state.oadaActions.getSoadaHistoricalReturnResponse
  if (response === undefined) {
    return undefined
  } else if (response.tag === 'Fail') {
    console.error(`Failed to get sOADA historical return: ${response.contents}`)
    return undefined
  } else {
    return response.contents
  }
}

export const selectStakeAuctionVolume = (state: RootState): StakeAuctionVolume | undefined => {
  const response = state.oadaActions.getStakeAuctionVolumeResponse
  if (response === undefined) {
    return undefined
  } else if (response.tag === 'Fail') {
    console.error(`Failed to get stake auction volume: ${response.contents}`)
    return undefined
  } else {
    return response.contents
  }
}

export type StakeAuctionVolumeData = {
  cumulativeVolume: number,
  intervals: StakeAuctionVolume
}

export const selectStakeAuctionVolumeData = (state: RootState): StakeAuctionVolumeData => {
  return {
    cumulativeVolume: selectStakeLockHistoricVolume(state) || 0,
    intervals: selectStakeAuctionVolume(state) || {}
  }
}

export const selectSoadaApyMovingAverage = (beforeEpoch?: number, epochCount: number = 6) => (state: RootState): number => {
  const oadaFrontendInfo = selectOadaFrontendInfo(state)
  const soadaHistoricalReturn = selectSoadaHistoricalReturn(state)
  let lastEpoch = beforeEpoch || (oadaFrontendInfo && relativeEpochToAbsoluteEpoch(oadaFrontendInfo?.currEpoch))
  if (soadaHistoricalReturn === undefined || lastEpoch === undefined)
    return 0.0

  lastEpoch -= 1

  let apySum = 0.0
  for (let i = 0; i < epochCount; i++) {
    const r = soadaHistoricalReturn[lastEpoch - i]
    const apy = (Math.round(1000 * Math.pow((r.numerator / r.denominator), 73)) / 1000) - 1.0 || 0.0
    apySum += apy
  }

  return Math.round(1000 * (apySum / epochCount)) / 1000
}

export const selectLockOptimizResponse = (state: RootState): BasicResponse<string> | undefined => {
  return state.oadaActions.lockOptimizResponse
}

export const selectUnlockOptimizResponse = (state: RootState): BasicResponse<string> | undefined => {
  return state.oadaActions.unlockOptimizResponse
}

export const selectOptimizToOptimInfo = (state: RootState): OptimizToOptimInfo | undefined => {
  const response = state.oadaActions.getOptimizToOptimInfoResponse
  if (response === undefined) {
    return undefined
  } else if (response.tag === 'Fail') {
    console.error(`Failed to get optimiz to optim info: ${response.contents}`)
    return undefined
  } else {
    return response.contents
  }
}

const getWalletUtxos = async (wallet: UITypes.Wallets.Wallet): Promise<[UTxO[], string]> => {
  const { utxos, collateralUtxos } = await getUtxos(wallet)
  let collateralUtxoTxOutRefs = collateralUtxos.map(lucidToGYTxOutRef)
  const nonCollateralUtxos = utxos.filter(utxo => !collateralUtxoTxOutRefs.includes(lucidToGYTxOutRef(utxo)))
  const mostRecentUtxos = getLatestUtxosFromPersistedVirtualWalletUtxoMap(nonCollateralUtxos)
  const userChangeAddress = await lucid.wallet.address()
  return [mostRecentUtxos, userChangeAddress]
}

const getRecipeBuildSendTx = async (
  utxos: UTxO[],
  changeAddress: string,
  rawResponse: Response,
  extraRecipe?: (recipe: TxRecipe) => TxRecipe
) => {
  const response: BasicResponse<TxRecipe> = await rawResponse.json();

  if (response.tag === 'Fail') {
    return response
  }

  const txRecipe = extraRecipe ? extraRecipe(response.contents) : response.contents
  const incompleteTx = txRecipeToTx(lucid)(txRecipe)
  console.log('AFTER')

  const unsignedTx = await incompleteTx.complete({
    change: {
      address: changeAddress
    },
    utxos: utxos
  }).catch(error => { return JSON.stringify(error) })

  if (typeof unsignedTx === 'string') {
    return { tag: 'Fail' as const, contents: `${unsignedTx}` }
  }

  console.log(`Hash: ${unsignedTx.toHash()}`)
  console.log(`Cbor: ${unsignedTx.toString()}`)

  const signedTx = await unsignedTx.sign().complete()

  const signedTxCbor = signedTx.toString()
  // TODO: also send through users wallet
  const signedTxRequest = {
    txCborHex: signedTxCbor
  }
  const signedTxRequestOptions = {
    method: "POST",
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
    body: Json.stringify(signedTxRequest),
  }
  const rawSignedTxResponse = await fetch(
    `${oadaEndpointsUrl}/submit-tx`,
    signedTxRequestOptions
  )

  const signedTxResponse: BasicResponse<string> = await rawSignedTxResponse.json()
  console.log('Submit tx response:')
  console.log(signedTxResponse)

  if (signedTxResponse.tag === 'OK') {
    const coreTxBody = signedTx.txSigned.body()
    const userInputRefs = utxos.map(utxo => {
      return `${utxo.txHash}#${utxo.outputIndex}`
    })
    const userAddressSet = new Set([changeAddress, ...utxos.map(utxo => utxo.address)])
    const virtualWalletUtxoMap = makeVirtualWalletUtxoMap(userInputRefs, userAddressSet, coreTxBody)
    const knownTxIds = utxos.map(utxo => `${utxo.txHash}#${utxo.outputIndex}`)
    updatePersistedVirtualWalletUtxoMap(knownTxIds, virtualWalletUtxoMap)
  }

  return signedTxResponse
}

// T = [0, 100]
// TODO: use Decimal.js so we can do these this more accurately
const calcClearingRate = (baseRate: Big, projectedRate: Big, sigmoidScalar: Big, sr: Big, r: Big, t: Big) => {
  const t_centered = t.sub(50)
  const t_normed = t.div(100)
  const t_centered_normed = t_centered.div(100)
  const sr_r_ratio = sr.div(r)

  const factorExponent = Big('5.6').add(Big('2.6').mul(t_normed)).toNumber()
  const factor = t_normed.lt(sr_r_ratio)
    ? Big(Big(1).add((sr_r_ratio.sub(t_normed)).div(Big(1).sub(t_normed))).toNumber() ** factorExponent)
    : Big(1)

  const exponential = Math.exp(Big(-1).mul(t_centered_normed.mul(sigmoidScalar)).toNumber())

  let clearingRate = baseRate.add(projectedRate.mul(Big(1).sub(Big(1).div(Big(1).add(exponential)))).mul(factor))

  return clearingRate
}

// bidApr is 1/10 of a percent
export const bidAmountToRequestedSize = (bidAmount: Big, bidApr: Big) => {
  return bidAmount.mul(1000).mul(73).div(bidApr)
}

// binary search
// bidApr is 1/10 of a percent
// returns clearing rate ~ bidApr
export const bidAmountToClearingRate = (
  bidAmount: Big, sr: Big, r: Big, t: Big, limit: number, initMinBidApr: Big, initMaxBidApr: Big, initBidApr: Big
) => {
  let prevMinBidApr = initMinBidApr
  let prevMaxBidApr = initMaxBidApr
  let prevBidApr = initBidApr
  let prevRequestedBidStakeSize = bidAmountToRequestedSize(bidAmount, initBidApr)
  let prevPotentialStakedReserves = sr.add(prevRequestedBidStakeSize)
  let potentialReserves = r
  let prevCr = Big(1000).mul(calcClearingRate(
    oadaNetwork.baseRate,
    oadaNetwork.projectedRate,
    oadaNetwork.sigmoidScalar,
    prevPotentialStakedReserves,
    potentialReserves,
    t
  ))

  let count = 0

  while (count < limit && !(prevCr.gte(prevBidApr) && prevCr.sub(prevBidApr).lte('1e-6'))) {
    if (prevCr.gt(prevBidApr)) {
      let nextMinBidApr = prevBidApr
      let nextMaxBidApr = prevMaxBidApr
      let nextBidApr = prevBidApr.add(prevMaxBidApr.sub(prevBidApr).div(2))
      let nextRequestedBidStakeSize = bidAmountToRequestedSize(bidAmount, nextBidApr)
      prevMinBidApr = nextMinBidApr
      prevMaxBidApr = nextMaxBidApr
      prevBidApr = nextBidApr
      prevPotentialStakedReserves = sr.add(nextRequestedBidStakeSize)
    } else {
      let nextMinBidApr = prevMinBidApr
      let nextMaxBidApr = prevBidApr
      let nextBidApr = prevBidApr.sub(prevBidApr.sub(prevMinBidApr).div(2))
      let nextRequestedBidStakeSize = bidAmountToRequestedSize(bidAmount, nextBidApr)
      prevMinBidApr = nextMinBidApr
      prevMaxBidApr = nextMaxBidApr
      prevBidApr = nextBidApr
      prevPotentialStakedReserves = sr.add(nextRequestedBidStakeSize)
    }
    prevCr = Big(1000).mul(calcClearingRate(
      oadaNetwork.baseRate,
      oadaNetwork.projectedRate,
      oadaNetwork.sigmoidScalar,
      prevPotentialStakedReserves,
      potentialReserves,
      t
    ))
    ++count
  }
  if (count >= limit) {
    console.log('Clearing rate search limit reached')
  }

  return prevCr.round(0, Big.roundUp)
}

export const stakeAmountToClearingRate = (
  stakeAmountAsLovelace: Big, sr: Big, r: Big, t: Big
) => {
  let requestedBidStakeSize = stakeAmountAsLovelace
  let stakedReserves = sr.add(requestedBidStakeSize)
  let reserves = r

  let cr = Big(1000).mul(calcClearingRate(
    oadaNetwork.baseRate,
    oadaNetwork.projectedRate,
    oadaNetwork.sigmoidScalar,
    stakedReserves,
    reserves,
    t
  ))

  return cr.round(0, Big.roundUp)
}

type BuyOadaRequest = {
  amount: bigint
}

export const buyOada = createAsyncThunk<
  BasicResponse<string>,
  BuyOadaRequest,
  {
    dispatch: AppDispatch,
    state: RootState,
    extra: Services,
    rejectValue: FailResponse
  }
>(
  'buyOada',
  async (request: BuyOadaRequest, thunkApi) => {
    console.log('BUY OADA', request)

    const requestOptions = {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: Json.stringify(request),
    };

    const rawResponse = await fetch(
      `${oadaEndpointsUrl}/buy-oada`,
      requestOptions,
    );

    const wallet = thunkApi.getState().wallet.wallet!
    const [utxos, changeAddress] = await getWalletUtxos(wallet)

    const addFee = (recipe: TxRecipe) => {
      oadaFeeAddress && oadaMintFee && recipe.txOuts.push({
        address: oadaFeeAddress,
        value: { 'lovelace': oadaMintFee },
        datum: null,
        refScript: null
      })
      return recipe
    }
    const signedTxResponse = await getRecipeBuildSendTx(utxos, changeAddress, rawResponse, addFee)

    return signedTxResponse
  }
)

type StakeOadaRequest = {
  amount: bigint
}

export const stakeOada = createAsyncThunk<
  BasicResponse<string>,
  StakeOadaRequest,
  {
    dispatch: AppDispatch,
    state: RootState,
    extra: Services,
    rejectValue: FailResponse
  }
>(
  'stakeOada',
  async (request: StakeOadaRequest, thunkApi) => {
    console.log('STAKE OADA', request)

    const wallet = thunkApi.getState().wallet.wallet!
    const [utxos, changeAddress] = await getWalletUtxos(wallet)

    const ownerPkh = bech32AddressToPaymentPkh(changeAddress)

    const serverRequest = {
      ownerPkh,
      returnAddressBech32: changeAddress,
      amount: request.amount,
    }

    const requestOptions = {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: Json.stringify(serverRequest),
    };

    const rawResponse = await fetch(
      `${oadaEndpointsUrl}/stake-oada`,
      requestOptions,
    );

    const addFee = (recipe: TxRecipe) => {
      oadaFeeAddress && oadaStakeFee && recipe.txOuts.push({
        address: oadaFeeAddress,
        value: { 'lovelace': oadaStakeFee },
        datum: null,
        refScript: null
      })
      return recipe
    }
    const signedTxResponse = await getRecipeBuildSendTx(utxos, changeAddress, rawResponse, addFee)

    return signedTxResponse
  }
)

type UnstakeOadaRequest = {
  amount: bigint
}

export const unstakeOada = createAsyncThunk<
  BasicResponse<string>,
  UnstakeOadaRequest,
  {
    dispatch: AppDispatch,
    state: RootState,
    extra: Services,
    rejectValue: FailResponse
  }
>(
  'unstakeOada',
  async (request: UnstakeOadaRequest, thunkApi) => {
    console.log('UNSTAKE OADA', request)

    const wallet = thunkApi.getState().wallet.wallet!
    const [utxos, changeAddress] = await getWalletUtxos(wallet)

    const ownerPkh = bech32AddressToPaymentPkh(changeAddress)

    const serverRequest = {
      ownerPkh,
      returnAddressBech32: changeAddress,
      amount: request.amount,
    }

    const requestOptions = {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: Json.stringify(serverRequest),
    };

    const rawResponse = await fetch(
      `${oadaEndpointsUrl}/unstake-oada`,
      requestOptions,
    );

    const addFee = (recipe: TxRecipe) => {
      oadaFeeAddress && oadaStakeFee && recipe.txOuts.push({
        address: oadaFeeAddress,
        value: { 'lovelace': oadaStakeFee },
        datum: null,
        refScript: null
      })
      return recipe
    }
    const signedTxResponse = await getRecipeBuildSendTx(utxos, changeAddress, rawResponse, addFee)

    return signedTxResponse
  }
)

export type BidType = "BidTypePartial" | "BidTypeFull"
type StakeAuctionBidRequest = {
  bidType: BidType,
  bidApy: Big,
  bidValue: GYValueOut,
  stakeAddressBech32: string
}

export const stakeAuctionBid = createAsyncThunk<
  BasicResponse<string>,
  StakeAuctionBidRequest,
  {
    dispatch: AppDispatch,
    state: RootState,
    extra: Services,
    rejectValue: FailResponse
  }
>(
  'stakeAuctionBid',
  async (request: StakeAuctionBidRequest, thunkApi) => {
    console.log('STAKE AUCTION BID', request)

    const wallet = thunkApi.getState().wallet.wallet!
    const [utxos, changeAddress] = await getWalletUtxos(wallet)

    const ownerPkh = bech32AddressToPaymentPkh(changeAddress)
    const stakeAddress = addressToStakeAddress(changeAddress)
    console.error(`stakeAddress: ${stakeAddress}`)

    if (stakeAddress === null) {
      return { tag: 'Fail', contents: `Could not get stake address from ${changeAddress}` }
    }

    const serverRequest = {
      ownerPkh,
      stakeAddressBech32: request.stakeAddressBech32,
      bidType: request.bidType,
      bidApy: BigInt(request.bidApy.toString()),
      bidValue: request.bidValue,
    }

    console.error(Json.stringify(serverRequest))

    const requestOptions = {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: Json.stringify(serverRequest),
    };

    const rawResponse = await fetch(
      `${oadaEndpointsUrl}/stake-auction-bid`,
      requestOptions,
    );

    const signedTxResponse = await getRecipeBuildSendTx(utxos, changeAddress, rawResponse)

    return signedTxResponse
  }
)

type CancelStakeAuctionBidRequest = {
  bidId: string,
}

export const cancelStakeAuctionBid = createAsyncThunk<
  BasicResponse<string>,
  CancelStakeAuctionBidRequest,
  {
    dispatch: AppDispatch,
    state: RootState,
    extra: Services,
    rejectValue: FailResponse
  }
>(
  'cancelStakeAuctionBid',
  async (request: CancelStakeAuctionBidRequest, thunkApi) => {
    console.log('CANCEL STAKE AUCTION BID', request)

    const wallet = thunkApi.getState().wallet.wallet!
    const [utxos, changeAddress] = await getWalletUtxos(wallet)

    const ownerPkh = bech32AddressToPaymentPkh(changeAddress)

    const serverRequest = {
      ownerPkh,
      txOutRef: bidIdToTxOutRef(request.bidId)
    }

    console.error(Json.stringify(serverRequest))

    const requestOptions = {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: Json.stringify(serverRequest),
    };

    const rawResponse = await fetch(
      `${oadaEndpointsUrl}/cancel-stake-auction-bid`,
      requestOptions,
    );

    const signedTxResponse = await getRecipeBuildSendTx(utxos, changeAddress, rawResponse)

    return signedTxResponse
  }
)

type CancelStakeOrderRequest = {
  orderId: string,
}

export const cancelStakeOrder = createAsyncThunk<
  BasicResponse<string>,
  CancelStakeOrderRequest,
  {
    dispatch: AppDispatch,
    state: RootState,
    extra: Services,
    rejectValue: FailResponse
  }
>(
  'cancelStakeOrder',
  async (request: CancelStakeOrderRequest, thunkApi) => {
    console.log('CANCEL STAKE ORDER', request)

    const wallet = thunkApi.getState().wallet.wallet!
    const [utxos, changeAddress] = await getWalletUtxos(wallet)

    const ownerPkh = bech32AddressToPaymentPkh(changeAddress)

    const serverRequest = {
      ownerPkh,
      txOutRef: request.orderId
    }

    console.error(Json.stringify(serverRequest))

    const requestOptions = {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: Json.stringify(serverRequest),
    };

    const rawResponse = await fetch(
      `${oadaEndpointsUrl}/cancel-stake-unstake-order`,
      requestOptions,
    );

    const signedTxResponse = await getRecipeBuildSendTx(utxos, changeAddress, rawResponse)

    return signedTxResponse
  }
)




export type StakeAuctionBidView = {
  txOutRef: string,
  assetClass: string,
  apy: number,
  amount: number,
  ownerPkh: string,
  bidType: BidType,
  stakeAddressBech32: string,
}

export type StakingAmoView = {
  odaoFeeBps: number,
  odaoSoada: number,
  soadaAmount: number,
  soadaBackingLovelace: number,
  soadaLimit: number
}

export type BatchStakeView = {
  adaAmount: number,
  oadaAmount: number,
  soadaAmount: number
}

export type StablePoolView = {
  stablePoolBaseAmount: number
  stablePoolOadaAmount: number
  stablePoolCircLpAmount: number
}

export type OadaStakeOrderView = {
  txOutRef: string
  oadaAmount: number
  adaAmount: number
  returnAddressBech32: string
}

export type OadaUnstakeOrderView = {
  txOutRef: string
  soadaAmount: number
  adaAmount: number
  returnAddressBech32: string
}

export type OadaFrontendInfo = {
  baseAssetClass: string,
  oadaAssetClass: string,
  soadaAssetClass: string,
  lpAssetClass: string,
  stakedReserves: number,
  totalReserves: number,
  timeIntervalIndex: number,
  currEpoch: number,
  currEpochEndPosixTime: number,
  clearingRate: number,
  stakingAmoView: StakingAmoView
  batchStakesView: BatchStakeView,
  stablePoolView: StablePoolView,
  ownerOadaStakeOrderViews: OadaStakeOrderView[],
  ownerOadaUnstakeOrderViews: OadaUnstakeOrderView[],
  bidViews: StakeAuctionBidView[],
  ownerBidViews: StakeAuctionBidView[],
}

export const getOadaFrontendInfo = createAsyncThunk<
  BasicResponse<OadaFrontendInfo>,
  void,
  {
    dispatch: AppDispatch,
    state: RootState,
    extra: Services,
    rejectValue: FailResponse
  }
>(
  'getOadaFrontendInfo',
  async (_request: void, thunkApi) => {
    console.log('GET OADA INFO')

    const wallet = thunkApi.getState().wallet.wallet
    let ownerPkh: string | null = null
    if (wallet !== null) {
      const [_utxos, changeAddress] = await getWalletUtxos(wallet)
      ownerPkh = bech32AddressToPaymentPkh(changeAddress)
    }

    const requestOptions = {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    };

    // const fragment = intercalate("&", queryParams);
    // if (fragment !== "") {
    //   url = url + "?" + fragment;
    // }
    let queryParam =
      ownerPkh !== null
        ? `?owner_pkh=${ownerPkh}`
        : ''
    const rawResponse = await fetch(
      `${oadaEndpointsUrl}/get-oada-frontend-info/${queryParam}`,
      requestOptions,
    );

    const response: BasicResponse<OadaFrontendInfo> = await rawResponse.json();
    return response
  }
)

export const getStakeLockHistoricVolume = createAsyncThunk<
  BasicResponse<number>,
  void,
  {
    dispatch: AppDispatch,
    state: RootState,
    extra: Services,
    rejectValue: FailResponse
  }
>(
  'getStakeLockHistoricVolume',
  async (_request: void, thunkApi) => {
    console.log('GET STAKE LOCK HISTORIC VOLUME')
    const requestOptions = {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    };

    const rawResponse = await fetch(
      `${oadaEndpointsUrl}/stake-lock-historic-vol`,
      requestOptions,
    );

    const response: BasicResponse<number> = await rawResponse.json();
    return response
  }
)


export type SoadaHistoricalReturn = {
  [epoch: string]: { "numerator": number, "denominator": number }
}


export const getSoadaHistoricalReturn = createAsyncThunk<
  BasicResponse<SoadaHistoricalReturn>,
  void,
  {
    dispatch: AppDispatch,
    state: RootState,
    extra: Services,
    rejectValue: FailResponse
  }
>(
  'getSoadaHistoricalReturn',
  async (_request: void, thunkApi) => {
    console.log('GET SOADA HISTORICAL RETURN')
    const requestOptions = {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    };

    const rawResponse = await fetch(
      `${oadaEndpointsUrl}/soada-historical-return`,
      requestOptions,
    );

    const response: BasicResponse<SoadaHistoricalReturn> = await rawResponse.json();
    return response
  }
)

export type StakeAuctionVolume = {
  [timeframe: string]: number
}

export const getStakeAuctionVolume = createAsyncThunk<
  BasicResponse<StakeAuctionVolume>,
  void,
  {
    dispatch: AppDispatch,
    state: RootState,
    extra: Services,
    rejectValue: FailResponse
  }
>(
  'getStakeAuctionVolume',
  async (_request: void, thunkApi) => {
    console.log('GET STAKE AUCTION VOLUME')
    const requestOptions = {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    };

    const timeFrames = ['1D', '7D', '30D']
    const timeFrameQueries = timeFrames.map(t => `timeframe[]=${t}`).join('&')
    const rawResponse = await fetch(
      `${oadaEndpointsUrl}/stake-auction-volume?${timeFrameQueries}`,
      requestOptions,
    );

    const response: BasicResponse<StakeAuctionVolume> = await rawResponse.json();
    return response
  }
)

type Token = {
  policyId: string,
  tokenName: string,
}

type OadaGeneralInfo =
  {
    networkId: string
    , adminToken: Token
    , feeClaimerToken: Token
    , baseAssetClass: string
    , oadaToken: Token
    , soadaToken: Token
    , feeClaimRuleWhitelistNft: Token
    , depositRuleNft: Token
    , oadaStakeRuleNft: Token
    , soadaStakeRuleNft: Token
    , controllerWhitelistNft: Token
    , stakingAmoNft: Token
    , dexStrategyWhitelistNft: Token
    , dexStrategyConfigWhitelistNft: Token
    , dexStrategyConfigWhitelistTxOutRef: string
    , dexStrategyRuleNft: Token
    , maxLpTokenAmount: bigint
    , depositGuardNum: bigint
    , depositGuardDen: bigint
    , collateralAmoNft: Token
    , collateralAmoTxOutRef: string
    , collateralAmoAmount: bigint
    , depositTxOutRefs: string
    , depositsAmount: bigint
    , unlockableStakeLocksTxOutRefs: string
    , unlockableStakeLocksAmount: bigint
    , lockedStakeLocksTxOutRefs: string
    , lockedStakeLocksAmount: bigint
    , dexStrategyNft: Token
    , dexStrategyTxOutRef: string
    , dexStrategyAmount: bigint
    , stablePoolNft: Token
    , stablePoolTxOutRef: string
    , stablePoolAmount: bigint
    , stablePoolLpToken: Token
    , amplCoeff: bigint
    , lpFeeNum: bigint
    , protocolFeeNum: bigint
  }

export const getOadaGeneralInfo = createAsyncThunk<
  BasicResponse<OadaGeneralInfo>,
  void,
  {
    dispatch: AppDispatch,
    state: RootState,
    extra: Services,
    rejectValue: FailResponse
  }
>(
  'getOadaGeneralInfo',
  async (_request: void, thunkApi) => {
    console.log('GET OADA INFO')

    const wallet = thunkApi.getState().wallet.wallet!
    const [_utxos, changeAddress] = await getWalletUtxos(wallet)

    const ownerPkh = bech32AddressToPaymentPkh(changeAddress)

    const requestOptions = {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    };

    const rawResponse = await fetch(
      `${oadaEndpointsUrl}/get-oada-frontend-info/${ownerPkh}`,
      requestOptions,
    );

    const response: BasicResponse<OadaGeneralInfo> = await rawResponse.json();

    return response
  }
)

// Optimiz Locks

type LockOptimizRequest = {
  lockupDays: number,
  optimizAmount: bigint
}

export const lockOptimiz = createAsyncThunk<
  BasicResponse<string>,
  LockOptimizRequest,
  {
    dispatch: AppDispatch,
    state: RootState,
    extra: Services,
    rejectValue: FailResponse
  }
>(
  'lockOptimiz',
  async (request: LockOptimizRequest, thunkApi) => {
    console.log('LOCK OPTIMIZ', request)

    const wallet = thunkApi.getState().wallet.wallet!
    const [utxos, changeAddress] = await getWalletUtxos(wallet)

    const ownerPkh = bech32AddressToPaymentPkh(changeAddress)

    const serverRequest = {
      ownerPkh,
      lockupDays: request.lockupDays,
      optimizAmount: request.optimizAmount
    }

    const requestOptions = {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: Json.stringify(serverRequest),
    };

    const rawResponse = await fetch(
      `${oadaEndpointsUrl}/lock-optimiz`,
      requestOptions,
    );

    // const addFee = (recipe: TxRecipe) => { 
    //   oadaFeeAddress && oadaMintFee && recipe.txOuts.push({
    //     address: oadaFeeAddress,
    //     value: { 'lovelace': oadaMintFee },
    //     datum: null,
    //     refScript: null
    //   })
    //   return recipe
    // }
    const signedTxResponse = await getRecipeBuildSendTx(utxos, changeAddress, rawResponse)

    return signedTxResponse
  }
)

type UnlockOptimizRequest = {
  optimizLockId: string,
}

export const unlockOptimiz = createAsyncThunk<
  BasicResponse<string>,
  UnlockOptimizRequest,
  {
    dispatch: AppDispatch,
    state: RootState,
    extra: Services,
    rejectValue: FailResponse
  }
>(
  'unlockOptimiz',
  async (request: UnlockOptimizRequest, thunkApi) => {
    console.log('UNLOCK OPTIMIZ', request)

    const wallet = thunkApi.getState().wallet.wallet!
    const [utxos, changeAddress] = await getWalletUtxos(wallet)

    const ownerPkh = bech32AddressToPaymentPkh(changeAddress)

    const serverRequest = {
      txOutRef: request.optimizLockId,
      ownerPkh,
      returnAddressBech32: changeAddress
    }

    console.error(Json.stringify(serverRequest))

    const requestOptions = {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: Json.stringify(serverRequest),
    };

    const rawResponse = await fetch(
      `${oadaEndpointsUrl}/unlock-optimiz`,
      requestOptions,
    );

    const signedTxResponse = await getRecipeBuildSendTx(utxos, changeAddress, rawResponse)

    return signedTxResponse
  }
)

export type OptimizLockView = {
  txOutRef: string,
  oadaAmount: number,
  optimizAmount: number,
  optimAmount: number,
  lovelaceAmount: number,
  startPosixTime: number,
  maxLockupDays: number,
  lockupRatio: [number, number],
  earlyForfeitRatio: [number, number],
  ownerPkh: string
}

export type OptimizToOptimInfo = {
  ownerOptimizLockViews: OptimizLockView[]
}

export const getOptimizToOptimInfo = createAsyncThunk<
  BasicResponse<OptimizToOptimInfo>,
  void,
  {
    dispatch: AppDispatch,
    state: RootState,
    extra: Services,
    rejectValue: FailResponse
  }
>(
  'getOptimizToOptimInfo',
  async (_request: void, thunkApi) => {
    console.log('GET OPTIMIZ TO OPTIM INFO')

    const wallet = thunkApi.getState().wallet.wallet
    let ownerPkh: string | null = null
    if (wallet !== null) {
      const [_utxos, changeAddress] = await getWalletUtxos(wallet)
      ownerPkh = bech32AddressToPaymentPkh(changeAddress)
    }

    const requestOptions = {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    };

    // const fragment = intercalate("&", queryParams);
    // if (fragment !== "") {
    //   url = url + "?" + fragment;
    // }
    let queryParam =
      ownerPkh !== null
        ? `?owner_pkh=${ownerPkh}`
        : ''
    const rawResponse = await fetch(
      `${oadaEndpointsUrl}/optimiz-to-optim-info/${queryParam}`,
      requestOptions,
    );

    const response: BasicResponse<OptimizToOptimInfo> = await rawResponse.json();
    return response
  }
)
