import {
  C,
  Data,
  Lucid,
  Tx,
} from 'lucid-cardano'
import OadaScripts from './oada/scripts/oada.json'
import NewUtilScripts from './oada/scripts/new-utils.json'
import { AssetClass } from "./bond/plutus-v1-encoders";
import {OptimTokenWhitelistPurpose, WhitelistProtocol, mkWhitelistUtils} from './whitelist';
import {mkScriptUtils} from './oada/utils';
import {toPlutusData} from './bond/schema';

export type OptimTokenUtilsParams = {
  optimTokenSoul: AssetClass
  optimSupplyOwner: AssetClass
}

export const initOptimToken = async ({
  lucid,
  params: {
    optimTokenSoul,
    optimSupplyOwner
  }
}: {
  lucid: Lucid
  params: OptimTokenUtilsParams
}) => {
  const {
    loadWhitelist, 
    whitelistAdd,
    newTx,
    referenceWhitelist
  } = mkWhitelistUtils(lucid, optimTokenSoul, WhitelistProtocol.OPTIM_TOKEN, 0)

  const { loadValidator } = mkScriptUtils(lucid)

  const optimSupplyWhitelist = loadWhitelist(OptimTokenWhitelistPurpose.SUPPLY_RULE)
  const optimSupplyOwnerRule = loadValidator(NewUtilScripts, "owner_token.withdraw", [toPlutusData(optimSupplyOwner)])
  const optimPolicy = loadValidator(OadaScripts, 'otoken_policy.mint', [optimSupplyWhitelist.hash])

  const includeToken = async(token: AssetClass): Promise<Tx> => {
    const tokenInput = await lucid.utxoByUnit(token.currencySymbol + token.tokenName)
    const tx = newTx()
      .collectFrom([tokenInput])
      .payToAddressWithData(
        tokenInput.address,
        {
          inline: tokenInput.datum ?? undefined,
          scriptRef: tokenInput.scriptRef ?? undefined
        },
        tokenInput.assets
      )

    return tx
  }

  const whitelistOwnerRule = async () => {
    return newTx()
      .compose(await whitelistAdd(
        OptimTokenWhitelistPurpose.SUPPLY_RULE,
        optimSupplyOwnerRule.hash,
        optimSupplyOwnerRule.validator.script))
      .compose(await includeToken(optimTokenSoul))
      .registerStake(optimSupplyOwnerRule.mkRewardAddress())
  }

  const mintOptim = async (quantity: bigint) => {
    return newTx()
      .compose(await referenceWhitelist(optimSupplyWhitelist, optimSupplyOwnerRule.hash))
      .withdraw(optimSupplyOwnerRule.mkRewardAddress(), 0n, Data.void())
      .compose(await includeToken(optimSupplyOwner))
      .mintAssets({ [optimPolicy.hash]: quantity }, Data.void())
      .attachMintingPolicy(optimPolicy.validator)
  }

  return {
    whitelistOwnerRule,
    mintOptim,
  }
}
